@import "rainbow";

div#loading {
	font-family: "Press Start 2P", "Lucida Console", monospace;
	font-size: 5vmin;
	letter-spacing: -0.1em;
	text-align: center;

	> figure > picture > img {
		height: 22vh;
		max-height: 22vh;
		max-width: 100vw;
		width: 24vh;
	}
}

.overlay {
	height: auto;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	z-index: 1000;
}